<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab
            id="IVRAllMissedCallsTab"
            label="IVR Missed/Untracked Calls [0]"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in ivrAllMissedCallsdata" :key="index">
                <!-- <IvrMissedCalls :userdata="data"  v-if="renderComponent" /> -->
                <IvrMissedCalls :userdata="data" />
              </div>
              <!-- <IvrMissedCalls :userdata="ivrAllMissedCallsdata" /> -->
            </div>
          </vs-tab>
          <vs-tab id="IVRTodayCallLogsTab" label="IVR Today Call Logs [0]">
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <!-- <h6>IVR Today Call Logs No Data </h6> -->
              <div v-for="(data, index) in ivrTodayCallLogsdata" :key="index">
                <IvrCallLogs :userdata="data" />
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DashboardEscalations from "../components/pagesComponents/DashboardEscalations.vue";
import Worklog from "../components/pagesComponents/DashboardWorklog.vue";
import CallLog from "../components/pagesComponents/DashboardCalllog.vue";
import StatCards from "../components/pagesComponents/DashboardStatcards.vue";
import axios from "axios";
import EventBus from "../components/eventbus.js";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import IvrCallLogs from "../components/pagesComponents/IvrCallLogs";
import IvrMissedCalls from "../components/pagesComponents/IvrMissedCalls";
export default {
  mounted() {
    EventBus.$on("update-dashboard", () => {
        if (this.$route.path === "/") {
          console.log("Update Dashboard Finished");
          // this.callPagination = true;
          this.single_fetch_api();
          // this.getNetEnquires();
        }
      });
    this.single_fetch_api();
  },
  beforeDestroy() {
    EventBus.$emit("unload-filter");
  },
  components: {
    Datepicker,
    Worklog,
    CallLog,
    StatCards,
    Treeselect,
    DateRangePicker,
    DashboardEscalations,
    IvrMissedCalls,
    IvrCallLogs,
  },
  data() {
    return {
      ivrTodayCallLogsdata: [],
      ivrAllMissedCallsdata: [],
      escalationText: "Escalations [0]",
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M5",
                  label: "M5",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "L2",
                  label: "L2",
                },
                {
                  id: "L3",
                  label: "L3",
                },
                {
                  id: "L4",
                  label: "L4",
                },
                {
                  id: "L6",
                  label: "L6",
                },
                {
                  id: "L5",
                  label: "L5",
                },
                {
                  id: "L3+",
                  label: "L3+",
                },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "L4-",
                  label: "L4-",
                },
                {
                  id: "L1",
                  label: "L1",
                },
              ],
            },
            {
              id: "P4",
              label: "P4",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                {
                  id: "M1",
                  label: "M1",
                },
              ],
            },
          ],
        },
      ],
      callPagination: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      escalations: [],
      initSelectedLevels: [],
      missed_hot_pocketpagination: false,
      delayspagination: false,
      todayspagination: false,
      waitingpagination: false,
      filteredpagination: false,
      untappedpagination: false,
      activeTabLInk: 0,
      activeTabcallLInk: 0,
      updatepagination: false,
      missedcallpagination: false,
      untrackedpagination: false,
      calllogpagination: false,
      netenquiriesPagination: true,
      flagpagination: false,
      levelsFilter: "",
      Untapped: [],
      Delays: [],
      Today: [],
      Waiting: [],
      ToUpdate: [],
      Untracked: [],
      CallLog: [],
      MissedCalls: [],
      delaysTotal: "0",
      updatePvalue: 1,
      netEnquiriesPvalue: 1,
      missedcallPvalue: 1,
      untrackedPvalue: 1,
      calllogPvalue: 1,
      delayPvalue: 1,
      todayPvalue: 1,
      waitingPvalue: 1,
      untappedPvalue: 1,
      missed_hot_pocketPvalue: 1,
      filteredPvalue: 1,
      flagPvalue: 1,
      delayPtotal: 0,
      netEnquiriesPtotal: 0,
      todayPtotal: 0,
      waitingPtotal: 0,
      missed_hot_pocketPtotal: 0,
      untappedPtotal: 0,
      updatePtotal: 0,
      filteredPtotal: 1,
      missedcallPtotal: 0,
      untrackedPtotal: 0,
      calllogPtotal: 0,
      flagPtotal: 0,
      missedcallslength: 0,
      flagSection: [],
      flagLabel: "Flag [0]",
    };
  },
  created() {},
  methods: {
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    single_fetch_api() {
      this.getIvrCallLogs();
    },
    getIvrCallLogs() {
      this.ivrTodayCallLogsdata = [];
      this.ivrAllMissedCallsdata = [];
      let user_id = localStorage.getItem("spoc_id");
      let obj = {
        spoc_id: user_id,
      };
      axios
        .get(`${constants.SERVER_API}getIVRCalls`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("IVRTodayCallLogsTab", response);
          // this.ivrTodayCallLogsdata = response.data.today_calls;
          this.ivrTodayCallLogsdata = [
            ...response.data.answered_calls
              .reduce((mp, o) => {
                if (!mp.has(o.phone_number))
                  mp.set(o.phone_number, { ...o, count: 0 });
                mp.get(o.phone_number).count++;
                return mp;
              }, new Map())
              .values(),
          ];
          this.ivrAllMissedCallsdata = [
            ...response.data.missed_calls
              .reduce((mp, o) => {
                if (!mp.has(o.phone_number))
                  mp.set(o.phone_number, { ...o, count: 0 });
                mp.get(o.phone_number).count++;
                return mp;
              }, new Map())
              .values(),
          ];
          console.log("ivrAllMissedCallsdata", this.ivrAllMissedCallsdata);
          document
            .getElementById("IVRTodayCallLogsTab")
            .getElementsByTagName("span")[0].innerText =
            " IVR Today Call Logs [" + this.ivrTodayCallLogsdata.length + "]";
          document
            .getElementById("IVRAllMissedCallsTab")
            .getElementsByTagName("span")[0].innerText =
            " IVR Missed/Untracked Calls [" +
            this.ivrAllMissedCallsdata.length +
            "]";
        })
        .catch((error) => {
         
          this.handleError(error);
        });
    },
  },
};
</script>
<style>
</style>
